<mzima-client-button
  tabindex="-1"
  fill="outline"
  [iconOnly]="true"
  color="light-gray"
  [data-qa]="'btn-close'"
  class="modal__close-btn"
  [mat-dialog-close]="false"
  ariaLabel="{{ 'modal.button.close' | translate }}"
>
  <mat-icon icon svgIcon="close"></mat-icon>
</mzima-client-button>

<strong mat-dialog-title>{{ donationConfig.title }}</strong>

<div mat-dialog-content>
  <p>{{ donationConfig.description }}</p>
  <div class="images">
    <div class="image" *ngFor="let image of donationConfig.images">
      <img [src]="image.original_file_url" [alt]="'app.donation' | translate" />
    </div>
  </div>

  <hr />

  <p class="soft-text" *ngIf="donationService.isMonetizationStarted">
    <mat-icon svgIcon="heart-filled"></mat-icon>
    {{ 'modal.donation.thank_you_for_your_donation_start' | translate }}
    {{ donationInfo?.assetCode }} {{ donationInfo?.formattedAmount }}
    {{ 'modal.donation.thank_you_for_your_donation_end' | translate }}
  </p>

  <ng-container *ngIf="!donationService.isMonetizationStarted">
    <p class="soft-text">
      {{ 'modal.donation.web_monetization_text' | translate }}
    </p>

    <a
      target="_blank"
      class="link-blue"
      href="https://www.veediefstal.co.za"
    >
      {{ 'modal.donation.how_can_i_donate' | translate }}
    </a>
  </ng-container>
</div>

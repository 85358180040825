<mzima-client-button
  tabindex="-1"
  fill="outline"
  [iconOnly]="true"
  color="light-gray"
  [data-qa]="'btn-close'"
  class="modal__close-btn"
  [mat-dialog-close]="false"
  ariaLabel="{{ 'modal.button.close' | translate }}"
>
  <mat-icon icon svgIcon="close"></mat-icon>
</mzima-client-button>

<strong mat-dialog-title>{{ 'nav.help_support' | translate }}</strong>

<div mat-dialog-content>
  <div matRipple class="menu-item" (click)="item?.action()" *ngFor="let item of menu">
    <h4 class="menu-item__title">{{ item.title }}</h4>
    <p *ngIf="item.description?.length">{{ item.description }}</p>
  </div>
  <div matRipple class="menu-item" (click)="openUrl('https://www.nptracker.co.za/legal.html')">
    <h4 class="menu-item__title">{{ 'app.terms_and_conditions' | translate }}</h4>
  </div>
  <div matRipple class="menu-item" (click)="openUrl('https://www.nptracker.co.za/legal.html')">
    <h4 class="menu-item__title">{{ 'app.privacy_policy' | translate }}</h4>
  </div>
</div>
